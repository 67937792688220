import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const ProfileGet = async () => {
  try {
    const res = await getData(`/auth/profile`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const GetCustomerWallet = async (onPageChange, perPage) => {
  try {
    const res = await getData(
      `/booking/customer-wallet?page=${onPageChange}&per_page=${perPage}`
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getBooking = async (selectedTimeframe, onPageChange, perPage) => {
  try {
    let url = `/booking?page=${onPageChange}&per_page=${perPage}`;
    if (selectedTimeframe) {
      url += `&type=${selectedTimeframe}`;
    }
    const response = await getData(url); // Assuming getData is a function that fetches data from the URL
    return response;
  } catch (error) {
    console.error(error);
  }
};
// export const getBooking = async (selectedTimeframe) => {
//   try {
//     const res = await getData(`/booking`);

//     return res;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getBookingHistory = async (
  selectedTimeframe,
  term,
  onPageChange,
  perPage
) => {
  try {
    let url = `/booking/history?page=${onPageChange}&per_page=${perPage}`;
    if (selectedTimeframe) {
      url += `&type=${selectedTimeframe}`;
    }
    if (term) {
      url += `&term=${term}`;
    }

    const response = await getData(url); // Assuming getData is a function that fetches data from the URL
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getSubscriptionHistory = async (onPageChange, perPage) => {
  try {
    let url = `/booking/subscription-history?page=${onPageChange}&per_page=${perPage}`;

    const response = await getData(url);
    return response;
  } catch (error) {
    console.error(error);
  }
};
// export const getBookingHistory = async () => {s
//   try {
//     const res = await getData(`/booking/history`);

//     return res;
//   } catch (error) {
//     console.error(error);
//   }
// };
export const getBookingSingle = async (id) => {
  try {
    const res = await getData(`/booking/single/${id}`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const EditProfile = async (data) => {
  try {
    const res = await postData(`/auth/profile`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const postCancelBooking = async (data) => {
  try {
    const res = await postData(`/booking/booking-cancel`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const postRating = async (data) => {
  try {
    const res = await postData(`/review/add`, data);
    return res;
  } catch (error) {
    console.error(error);
  }
};
export const cancelBooking = async (id) => {
  try {
    const res = await getData(`/without-login/all-cancel-booking`);
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const ratingGet = async () => {
  try {
    const res = await getData(`/review/show`);

    return res;
  } catch (error) {
    console.error(error);
  }
};
export const UserGet = async () => {
  try {
    const res = await getData(`/admin/usersingleget`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const UserEdit = async (data) => {
  try {
    const res = await postData(`/admin/userEdit`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const ChangePassword = async (data) => {
  try {
    const res = await postData(`/admin/change-password`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const changeEmail = async (data) => {
  try {
    const res = await postData(`/admin/change-email`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const ChangePasswordWL = async (data) => {
  try {
    const res = await postData(`/admin/change-password-wl`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const CheckExist = async (data) => {
  try {
    const res = await postData(`/auth/check-exist`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const emailOtpGet = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/email-otp-send`,
      data
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const emailOtpVerify = async (data) => {
  try {
    const res = await postData(`/without-login/otp/email-verify-otp`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getLatLong = async (data) => {
  try {
    const res = await getData(`/without-login/studio/lat-log?pincode=${data}`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getLatLongDistance = async (pincode, secondLat, secondLong) => {
  try {
    const res = await getData(
      `/without-login/studio/lat-log-distance?pincode=${pincode}&secondLat=${secondLat}&secondLong=${secondLong}`
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const recheduleDate = async (id, data) => {
  try {
    const res = await postData(`/booking/check-rechedule/${id}`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const recheduleDateSuccessfully = async (data) => {
  try {
    const res = await postData(`/booking/rechedule`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const walletAmount = async (data) => {
  try {
    const res = await postData(`/admin/wallet`, data);

    return res;
  } catch (error) {
    console.error(error);
  }
};
