import React, { useContext, useState } from "react";

import Modal from "react-bootstrap/Modal";

import Success_modal from "../Success_modal/Success_modal";
import { Context } from "../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";

const Withdraw_req_modal = (props) => {
  const [modalShowSuccess, setModalShowSuccess] = React.useState(false);

  const { postData, signin, userdata, isTokenValid } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();

  const [successModal, setSuccessModal] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await postData(`/booking/wallet-request`, data);

      if (res?.success) {
        setModalShowSuccess(true);
        setTimeout(() => {
          setModalShowSuccess(false);
          if (props.onHide) {
            props.onHide(true);
          }
          reset();
        }, 3000);

        isTokenValid();
      } else {
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="withdrawmodal "
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Withdrawal Request
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form role="form">
            <div className="col-md-12">
              <div className="main-form-section mt-3">
                <Form.Group>
                  <Form.Label>Amount</Form.Label>

                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="amount"
                      placeholder="Amount"
                      className={classNames("", {
                        "is-invalid": errors?.amount,
                      })}
                      {...register("amount", {
                        required: "Amount is required",
                        validate: (value) => {
                          const numericValue = Number(value);

                          // Check if the value is a number and an integer
                          if (!Number.isInteger(numericValue)) {
                            return "Amount must be an integer.";
                          }

                          // Check if the value is non-negative
                          if (numericValue < 0) {
                            return "Amount cannot be negative.";
                          }

                          // Check if the amount is less than or equal to wallet balance
                          if (numericValue > userdata?.amount) {
                            return `Insufficient Wallet Balance. Available: ${userdata?.amount}`;
                          }

                          // If all conditions are satisfied, return true
                          return true;
                        },
                      })}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                        // .slice(0, 2); // Restrict input length to 2 characters (for 99 max)
                      }}
                    />
                  </InputGroup>

                  {errors.amount && (
                    <span className="text-danger">{errors.amount.message}</span>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Account Number</Form.Label>

                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="account_no"
                      placeholder="Account Number "
                      className={classNames("", {
                        "is-invalid": errors?.account_no,
                      })}
                      {...register("account_no", {
                        required: "Account Number is required",
                      })}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                        // .slice(0, 2); // Restrict input length to 2 characters (for 99 max)
                      }}
                    />
                  </InputGroup>
                  {errors.account_no && (
                    <span className="text-danger">
                      {errors.account_no.message}
                    </span>
                  )}
                </Form.Group>{" "}
                <Form.Group>
                  <Form.Label>IFSC Code</Form.Label>

                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="ifsc_code"
                      placeholder="IFSC Code"
                      className={classNames("", {
                        "is-invalid": errors?.ifsc_code,
                      })}
                      {...register("ifsc_code", {
                        required: "IFSC Code is required",
                      })}
                    />
                  </InputGroup>
                  {errors.ifsc_code && (
                    <span className="text-danger">
                      {errors.ifsc_code.message}
                    </span>
                  )}
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button className="close-btn" onClick={props.onHide}>
            Close
          </Button>
          <Button className="save-btn" onClick={handleSubmit(onSubmit)}>
            Submit Request
          </Button>
        </Modal.Footer>
      </Modal>

      <Success_modal
        show={modalShowSuccess}
        smalltext="Wallet Request Send Successfully"
        onHide={() => setModalShowSuccess(false)}
      />
    </>
  );
};

export default Withdraw_req_modal;
