import { getData } from "../../api";

// Blog
// export const mediaSpace = async (id,premium) => {
//     try {
//         const url=getData(`/without-login/studio/all-studio?category_id=${id}`)
//         if(premium){
//             url+`premium=${premium}`
//         }
//         return await url;
//     } catch (error) {
//         console.error(error);
//     }
// };

export const mediaSpace = async (
  currentPage,
  id,
  premium,
  location,
  price,
  rating,
  latitude,
  longitude,
  range
) => {
  try {
    let url = `/without-login/studio/all-studio?page=${currentPage}&category_id=${id}`;

    if (location) {
      url += `&location=${location.location}`;
    }

    if (premium) {
      url += `&premium=${premium}`;
    }

    if (price) {
      url += `&price=${price.price || price.priceRange.max}`;
    }

    if (rating) {
      url += `&rating=${rating.rating}`;
    }

    if (latitude) {
      url += `&latitude=${latitude}`;
    }
    if (longitude) {
      url += `&longitude=${longitude}`;
    }
    if (range) {
      url += `&range=${range}`;
    }

    const response = await getData(url);
    return response;
  } catch (error) {
    console.error("Error fetching media space data:", error);
    throw error; // Re-throw the error for the calling function to handle it
  }
};

export const mediaSpaceDetails = async (id) => {
  try {
    return await getData(`/without-login/studio/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const perticularUserBookings = async () => {
  try {
    return await getData(`/admin/booking-of-perticular-user`);
  } catch (error) {
    console.error(error);
  }
};

export const userSubscriptionDetails = async () => {
  try {
    return await getData(`/admin/user-subscription-details`);
  } catch (error) {
    console.error(error);
  }
};

// Faq

export const faqCategory = async (id) => {
  try {
    return await getData(`/faq`);
  } catch (error) {
    console.error(error);
  }
};

export const FaqCategoryData = async (id) => {
  try {
    return await getData(`/faq`);
  } catch (error) {
    console.error(error);
  }
};

export const FaqData = async (id) => {
  try {
    return await getData(`/faq/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const FaqFAqData = async (id) => {
  try {
    return await getData(`/faq/all`);
  } catch (error) {
    console.error(error);
  }
};
