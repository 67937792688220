import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { ColorRing } from "react-loader-spinner";
const Reshedule_Wallet_Payment_modal = (props) => {
  const { register, handleSubmit, watch } = useForm();

  // Watching for selected payment type
  const selectedPaymentType = watch("payment_type");

  // Automatically update the parent with the selected payment type
  useEffect(() => {
    if (props.setPaymentType) {
      props.setPaymentType(selectedPaymentType);
    }
  }, [selectedPaymentType, props]);

  const onSubmit = (data) => {
    if (props.handleSubmit) {
      props.setPaymentType(data.payment_type);
      props.handleSubmit(data);
    }
  };

  // console.log(selectedPaymentType);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="booking-cancel-modal Wallet_Payment_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h4 className="title">Booking Payment</h4>
          </div>

          <div className="reasons-div">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 text-center">
                <Form.Check
                  inline
                  label="Wallet"
                  name="payment_type"
                  type="radio"
                  value="wallet"
                  id="inline-radio-1"
                  {...register("payment_type", { required: true })}
                />
                <Form.Check
                  inline
                  label="Online"
                  name="payment_type"
                  type="radio"
                  value="Online"
                  id="inline-radio-2"
                  {...register("payment_type", { required: true })}
                />
              </div>

              {props.loading ? (
                <div className="d-flex justify-content-center mt-5">
                  <ColorRing
                    className="blocks-wrapper"
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#e15b64",
                      "#f47e60",
                      "#f8b26a",
                      "#abbd81",
                      "#849b87",
                    ]}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-5">
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={props.onHide}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="submit-btn">
                    SUBMIT
                  </button>
                </div>
              )}

              {/* <div className="d-flex justify-content-center mt-5">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={props.onHide}
                >
                  CANCEL
                </button>
                <button type="submit" className="submit-btn">
                  SUBMIT
                </button>
              </div> */}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Reshedule_Wallet_Payment_modal;
